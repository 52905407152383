import $ from 'jquery';

import './vendor/bootstrap';

import Dropdown from 'bootstrap/dropdown';
import EventHandler from 'bootstrap/dom/event-handler';
import SelectorEngine from 'bootstrap/dom/selector-engine';

import './lib/sticky';
import './lib/animations';
import initMediaPlayer from './lib/media';

// Export jQuery for external usage
window.jQuery = window.$ = $; // eslint-disable-line no-multi-assign

// constantes
const SELECTOR_DATA_TOGGLE = '[data-bs-toggle="dropdown"]';
const navbarContent = SelectorEngine.findOne('#navbarSupportedContent');

// -----------------------------------------------------------------------------
// Main application
// -----------------------------------------------------------------------------

$(window).on('load.app', () => {
  $('.no-js').removeClass('no-js');

  // charge les lecteurs audio de la page
  const plyrOptions =
    typeof window.STATIC_URL === 'undefined'
      ? {}
      : {
          // évite de passer par un CDN tiers
          iconUrl: `${window.STATIC_URL}img/plyr.svg`,
        };
  document
    .querySelectorAll('.audio-player, .video-player')
    .forEach((el) => initMediaPlayer(el, plyrOptions));

  EventHandler.on(
    navbarContent,
    'mouseover',
    SELECTOR_DATA_TOGGLE,
    function (event) {
      event.preventDefault();
      Dropdown.clearMenus(event);
      Dropdown.getOrCreateInstance(this).show();
    }
  );
});
