const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    const cls = entry.target.getAttribute('data-animate');
    entry.target.classList.toggle(cls, entry.isIntersecting);
  });
});

const blocks = document.querySelectorAll('[data-animate]');
for (const block of blocks) {
  observer.observe(block);
}
