import Plyr from 'plyr/src/js/plyr';

/**
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const Default = {
  controls: [
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'play-large',
  ],
  invertTime: false,
};

/**
 * ------------------------------------------------------------------------
 * Fonctions
 * ------------------------------------------------------------------------
 */

function initMediaPlayer(element, options) {
  return new Plyr(element, { ...Default, ...options });
}

export default initMediaPlayer;
