import $ from 'jquery';

/**
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const NAME = 'sticky';
const DATA_KEY = 'app.sticky';
const EVENT_KEY = `.${DATA_KEY}`;
const DATA_API_KEY = '.data-api';
const JQUERY_NO_CONFLICT = $.fn[NAME];

const Event = {
  RESIZE: `resize${EVENT_KEY}`,
  SCROLL: `scroll${EVENT_KEY}`,
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
};

const ClassName = {
  STICKY: 'sticky',
};

const Selector = {
  DATA_STICKY: '[data-sticky]',
};

/**
 * ------------------------------------------------------------------------
 * Classes
 * ------------------------------------------------------------------------
 */

class Sticky {
  constructor(element) {
    this._element = element;

    this._isStuck = null;
    this._height = 0;
    this._offset = 0;
    this._scrollHeight = 0;

    // Recherche le conteneur qui servira notamment à remplacer l'élément
    // et sa hauteur quand il est fixé
    $(this._element).wrap('<div></div>');
    this._container = $(this._element).parent()[0];

    $(window).on(`${Event.RESIZE}, ${Event.SCROLL}`, (event) =>
      this._process(event)
    );

    this.refresh();
    this._process();
  }

  // Public

  refresh() {
    this._height = this._getHeight();
    this._offset = this._getOffset();
    this._scrollHeight = this._getScrollHeight();

    this._isStuck = null;
  }

  dispose() {
    $.removeData(this._element, DATA_KEY);
    $(window).off(`${Event.RESIZE}, ${Event.SCROLL}`);

    this._element = null;
    this._container = null;
    this._isStuck = null;
    this._height = null;
    this._offset = null;
    this._scrollHeight = null;
  }

  // Privé

  _getHeight() {
    return this._element.getBoundingClientRect().height;
  }

  _getOffset() {
    // Utilise le conteneur dans le cas où l'élément serait déjà fixé
    return $(this._container).offset().top;
  }

  _getScrollHeight() {
    return this._element.scrollHeight;
  }

  _process() {
    const scrollTop = window.pageYOffset;
    const offset = scrollTop - this._offset;

    // Rafraichie les propriétés si la zone d'affichage a changé
    if (this._scrollHeight !== this._getScrollHeight()) {
      this.refresh();
    }

    if (offset > 0) {
      if (this._isStuck !== true) {
        this._setSticky();
      }
    } else if (this._isStuck !== false) {
      this._removeSticky();
    }
  }

  _setSticky() {
    this._isStuck = true;

    this._container.style.height = `${this._height}px`;
    $(this._element).addClass(ClassName.STICKY);
  }

  _removeSticky() {
    this._isStuck = false;

    $(this._element).removeClass(ClassName.STICKY);
    this._container.style.height = 'auto';
  }

  // Static

  static _jQueryInterface(config) {
    return this.each(function () {
      let data = $(this).data(DATA_KEY);

      if (!data) {
        data = new Sticky(this);
        $(this).data(DATA_KEY, data);
      }

      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config]();
      }
    });
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

$(window).on(Event.LOAD_DATA_API, () => {
  const stickys = [].slice.call(
    document.querySelectorAll(Selector.DATA_STICKY)
  );
  for (let i = 0, len = stickys.length; i < len; i++) {
    const $sticky = $(stickys[i]);
    Sticky._jQueryInterface.call($sticky, $sticky.data());
  }
});

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME] = Sticky._jQueryInterface;
$.fn[NAME].Constructor = Sticky;
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT;
  return Sticky._jQueryInterface;
};

export default Sticky;
